* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  touch-action: none;
  overflow-x: hidden;
}
